import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = {
  key: 5,
  class: "mb-24 md:mb-48"
}
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumb = _resolveComponent("Breadcrumb")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_CallToAction = _resolveComponent("CallToAction")!
  const _component_Heading = _resolveComponent("Heading")!
  const _component_Column = _resolveComponent("Column")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_TitleAndList = _resolveComponent("TitleAndList")!
  const _component_Container = _resolveComponent("Container")!
  const _component_Partners = _resolveComponent("Partners")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_AgileTeams = _resolveComponent("AgileTeams")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_DynamicContent = _resolveComponent("DynamicContent")!
  const _component_OpenSourcePackageSkeleton = _resolveComponent("OpenSourcePackageSkeleton")!
  const _component_OpenSourcePackage = _resolveComponent("OpenSourcePackage")!
  const _component_Button = _resolveComponent("Button")!
  const _component_BlogCard = _resolveComponent("BlogCard")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, null, {
      default: _withCtx(() => [
        (_ctx.service?.title)
          ? (_openBlock(), _createBlock(_component_Breadcrumb, {
              key: 0,
              class: "mt-32 lg:mt-48",
              paths: [
                        { route: _ctx.Route.Home, text: 'Home' },
                        { route: _ctx.Route.WhatWeDo, text: 'What we do' },
                        { route: { name: _ctx.Route.Services, params: { slug: _ctx.service.slug } }, text: _ctx.service?.title }
                    ]
            }, null, 8, ["paths"]))
          : _createCommentVNode("", true),
        (_ctx.service?.page_header_heading && _ctx.service?.page_header_content)
          ? (_openBlock(), _createBlock(_component_PageHeader, {
              key: 1,
              class: "mt-7",
              header: _ctx.service?.page_header_heading,
              "sub-header": _ctx.service?.page_header_content,
              "header-span": [ 2, 5 ]
            }, null, 8, ["header", "sub-header"]))
          : _createCommentVNode("", true),
        _createVNode(_component_Grid, {
          fluid: "",
          class: "mt-20 md:mt-28"
        }, {
          default: _withCtx(() => [
            (_ctx.service?.cover)
              ? (_openBlock(), _createBlock(_component_Picture, {
                  key: 0,
                  image: _ctx.service?.cover,
                  width: '100%',
                  height: _ctx.service?.cover.size.value === 'fullsize' ? '100%' : [ 220, 440 ],
                  maxWidth: _ctx.service?.cover.size.value === 'fullsize' ? null : '100%',
                  cover: "",
                  class: "services__video"
                }, null, 8, ["image", "height", "maxWidth"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.service?.include_cta_section)
          ? (_openBlock(), _createBlock(_component_CallToAction, {
              key: 2,
              class: "mt-28",
              label: _ctx.service.cta_label,
              "button-label": _ctx.service.cta_button_label,
              action: _ctx.service.cta_action
            }, null, 8, ["label", "button-label", "action"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AOS, {
          type: "fade-up",
          easing: "ease",
          duration: "800",
          delay: "1000"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_Heading, {
                        tag: "h2",
                        class: "text-black",
                        innerHTML: _ctx.service?.service_section_title
                      }, null, 8, ["innerHTML"])
                    ])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_AOS, {
          type: "fade-up",
          easing: "ease",
          duration: "800",
          delay: "1000"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service?.service_section_descriptions, (item, index) => {
                  return (_openBlock(), _createBlock(_component_Column, {
                    key: index,
                    span: _ctx.service?.service_section_style?.value ?? 2,
                    class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Heading, {
                        tag: "h3",
                        "less-margin": ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item?.title), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_Paragraph, {
                        class: "text-gray-600 md:pr-10",
                        innerHTML: item?.description
                      }, null, 8, ["innerHTML"])
                    ]),
                    _: 2
                  }, 1032, ["span", "class"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service?.service_section_tech_and_team, (feature, index) => {
          return (_openBlock(), _createBlock(_component_Container, {
            fluid: "",
            key: index
          }, {
            default: _withCtx(() => [
              _createVNode(_component_TitleAndList, {
                title: feature.title,
                list: feature.items,
                index: index,
                class: "mt-28"
              }, null, 8, ["title", "list", "index"])
            ]),
            _: 2
          }, 1024))
        }), 128)),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: 800,
          delay: 500
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Grid, { class: "mt-16 lg:mt-24" }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      heading: "",
                      innerHTML: _ctx.service?.featured_clients_section_title
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_Partners, { class: "mt-12 md:mt-20" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.service?.related_cases_title || _ctx.service?.related_cases_heading)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 3,
              type: "fade",
              easing: "ease",
              duration: 800,
              delay: 500
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "mt-24 md:mt-48" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2, 5, 4 ] }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          heading: "",
                          innerHTML: _ctx.service?.related_cases_title
                        }, null, 8, ["innerHTML"]),
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          class: "mt-12 md:mt-20",
                          innerHTML: _ctx.service?.related_cases_heading
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.service?.related_cases.length)
          ? (_openBlock(), _createBlock(_component_Projects, {
              key: 4,
              data: _ctx.service?.related_cases,
              class: "pt-16 mb-24 md:mb-48",
              "second-column-offset": ""
            }, null, 8, ["data"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_2)),
        (_ctx.service?.service_2_section_title)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 6,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2,4 ] }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_3, [
                          _createVNode(_component_Heading, {
                            tag: "h2",
                            class: "text-black",
                            innerHTML: _ctx.service?.service_2_section_title
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.service?.service_2_section_descriptions?.length)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 7,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service?.service_2_section_descriptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_Column, {
                        key: index,
                        span: _ctx.service?.service_2_section_style?.value ?? 2,
                        class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Heading, {
                            tag: "h3",
                            "less-margin": ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item?.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Paragraph, {
                            class: "text-gray-600 md:pr-10",
                            innerHTML: item?.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1032, ["span", "class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.service?.team_content && _ctx.service?.team_heading)
          ? (_openBlock(), _createBlock(_component_AgileTeams, {
              key: 8,
              class: "my-24 md:mt-48 md:mb-0",
              title: _ctx.service?.team_heading ?? '',
              content: _ctx.service?.team_content ?? '',
              link: _ctx.service?.team_linkentry,
              "link-label": _ctx.service?.team_linklabel
            }, null, 8, ["title", "content", "link", "link-label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Blue
    }, {
      default: _withCtx(() => [
        (_ctx.service?.include_open_source)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_Grid, { class: "md:pt-48" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, { span: [ 2, 4 ] }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AOS, {
                        type: "fade-up",
                        easing: "ease",
                        duration: 800,
                        delay: 500
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Paragraph, {
                            heading: "",
                            innerHTML: _ctx.service?.open_source_section_title
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (_ctx.service?.open_source_section_content)
                    ? (_openBlock(), _createBlock(_component_Column, {
                        key: 0,
                        span: 3,
                        class: "items-end"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AOS, {
                            type: "fade-up",
                            easing: "ease",
                            duration: 800,
                            delay: 500
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_DynamicContent, {
                                content: _ctx.service?.open_source_section_content
                              }, null, 8, ["content"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.service?.open_source_section_heading)
                ? (_openBlock(), _createBlock(_component_Grid, {
                    key: 0,
                    class: "mt-16 lg:mt-20"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Column, { span: [ 2, 5, 4 ] }, {
                        default: _withCtx(() => [
                          _createVNode(_component_AOS, {
                            type: "fade-up",
                            easing: "ease",
                            duration: 800,
                            delay: 500
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Heading, {
                                tag: "h2",
                                innerHTML: _ctx.service?.open_source_section_heading
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_Container, {
                fluid: "",
                class: _normalizeClass(["mt-16 space-y-24 md:mt-20", { 'mb-24 md:mb-48': !_ctx.service.open_source_section_entry }])
              }, {
                default: _withCtx(() => [
                  (_ctx.isLoading)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(2, (rows) => {
                        return _createVNode(_component_OpenSourcePackageSkeleton, { key: rows })
                      }), 64))
                    : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.openSourcePackages, (pkg, index) => {
                        return (_openBlock(), _createBlock(_component_OpenSourcePackage, {
                          key: index,
                          data: pkg.data,
                          featured: ""
                        }, null, 8, ["data"]))
                      }), 128))
                ]),
                _: 1
              }, 8, ["class"]),
              (_ctx.service.open_source_section_entry)
                ? (_openBlock(), _createBlock(_component_Container, {
                    key: 1,
                    fluid: "",
                    class: "mt-16 mb-24 md:mt-24 lg:mb-48"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Grid, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, { span: [ 2, 6 ] }, {
                            default: _withCtx(() => [
                              _createVNode(_component_AOS, {
                                type: "fade-up",
                                easing: "ease",
                                duration: 800,
                                delay: 500
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Button, {
                                    style: {"height":"75px"},
                                    route: _ctx.service.open_source_section_entry.slug
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.service.open_source_section_label), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["route"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.service?.service_3_section_title)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 1,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "mt-24 md:mt-48" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, { span: [ 2,4 ] }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, [
                          _createVNode(_component_Heading, {
                            tag: "h2",
                            class: "text-black",
                            innerHTML: _ctx.service?.service_3_section_title
                          }, null, 8, ["innerHTML"])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.service?.service_3_section_descriptions?.length)
          ? (_openBlock(), _createBlock(_component_AOS, {
              key: 2,
              type: "fade-up",
              easing: "ease",
              duration: "800",
              delay: "1000"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, null, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service?.service_3_section_descriptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_Column, {
                        key: index,
                        span: _ctx.service?.service_3_section_style?.value ?? 2,
                        class: _normalizeClass([ index === 0 ? 'sm:mt-10 mt-20' : 'mt-20' ])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Heading, {
                            tag: "h3",
                            "less-margin": ""
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item?.title), 1)
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_Paragraph, {
                            class: "text-gray-600 md:pr-10",
                            innerHTML: item?.description
                          }, null, 8, ["innerHTML"])
                        ]),
                        _: 2
                      }, 1032, ["span", "class"]))
                    }), 128))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.service?.related_blogs?.length)
          ? (_openBlock(), _createBlock(_component_Grid, {
              key: 3,
              class: "mt-24 md:mt-48"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Column, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      innerHTML: _ctx.service?.related_blogs_title,
                      heading: ""
                    }, null, 8, ["innerHTML"]),
                    _createVNode(_component_Heading, {
                      tag: "h2",
                      class: "mt-12 md:mt-20",
                      innerHTML: _ctx.service?.related_blogs_heading
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.service?.related_blogs, (blog, index) => {
                  return (_openBlock(), _createBlock(_component_BlogCard, {
                    key: index,
                    blog: blog,
                    class: "mt-12 md:mt-20",
                    "is-left": index % 2 == 0,
                    small: ""
                  }, null, 8, ["blog", "is-left"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Container, {
          class: "mt-24 md:mt-48",
          fluid: ""
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AOS, {
              type: "fade-up",
              easing: "ease",
              duration: 800,
              delay: 1000
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Grid, { class: "services__reason" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, {
                      class: "pb-12 services__reason__heading",
                      push: [ 0, 1 ],
                      span: [ 2,4 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Heading, {
                          tag: "h2",
                          innerHTML: _ctx.service?.why_section_title
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_Column, {
                      class: "pb-20",
                      push: [ 0, 1 ],
                      span: [ 2,4 ]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.service?.why_section_description
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.service?.why_section_reasons)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.service?.why_section_reasons, (reason, index) => {
                  return (_openBlock(), _createBlock(_component_Grid, {
                    class: "services__reason",
                    key: index
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AOS, {
                        type: "fade-up",
                        easing: "ease",
                        duration: 800,
                        delay: 700
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            class: "pt-20 services__reason__heading sm:pb-6 md:pb-12",
                            push: [ 0, 1 ],
                            span: [ 2,4 ]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Heading, {
                                tag: "h2",
                                innerHTML: reason.title
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_AOS, {
                        type: "fade-up",
                        easing: "ease",
                        duration: 800,
                        delay: 700
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Column, {
                            class: "pb-20",
                            push: [ 0, 1 ],
                            span: [ 2,4 ]
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Paragraph, {
                                innerHTML: reason.description,
                                class: "pt-1"
                              }, null, 8, ["innerHTML"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.service?.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.service?.seo,
          "structured-data": _ctx.service?.seo_structured_data,
          published: _ctx.service.date,
          modified: _ctx.service.last_modified
        }, null, 8, ["seo", "structured-data", "published", "modified"]))
      : _createCommentVNode("", true)
  ], 64))
}